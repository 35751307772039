// npm packages
window.$ = require('jquery');
window.jQuery = window.$;

require('webui-popover/dist/jquery.webui-popover.min.js');
require('tablesaw/dist/stackonly/tablesaw.stackonly.js');
require('tablesaw/dist/tablesaw-init.js');

require('jquery.kinetic/jquery.kinetic.min.js');
window.Promise = require('es6-promise/dist/es6-promise.min.js');

// custom local js
require('./modernizr-custom.js');
